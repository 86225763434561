@import '../../Styles/Mixin.scss';

#blog {
    #banner {
        background-image: url(../../images/duvidas/banner.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 469px;
        padding-top: 287px;
        border-bottom: 6px solid $color_gold;
        padding-top: 150px;

        a {
            font-size: 21px;
            font-weight: 700;
            position: relative;

            &:hover {
                color: $color_gold;
                text-decoration: none;
            }

            &:after {
                content: "";
                height: 20px;
                width: 1px;
                position: absolute;
                right: -11px;
                bottom: 5px;
                background-color: $color_gold;
            }

            &:last-child:after {
                display: none;
            }
        }
    }

    @media screen and (max-width: 365px) {
        #banner {
            height: 340px;
            padding: 60px 10px 0 10px;


            .bt {
                margin-top: 50px;
            }
        }
    }

    #conteudo {
        .map {
            h3 {
                margin-top: 100px;
                position: relative;

                &:after {
                    content: "";
                    height: 1px;
                    width: 80%;
                    background: #CECECE;
                    display: inline-block;
                    position: absolute;
                    right: 33px;
                    top: 16px;
                }

                &:before {
                    content: "";
                    width: 424px;
                    height: 432px;
                    background-image: url(../../images/atuacao/image.png);
                    position: absolute;
                    right: 140px;
                    top: -25px;
                }
            }

            .dual-list {
                display: flex;
                padding: 30px 0 80px 150px;

                .list-1 {
                    margin-right: 60px;

                    div {
                        margin-bottom: 15px;

                        p {
                            font-weight: 700;
                        }

                        span {
                            display: block;
                            position: relative;
                            padding-left: 20px;

                            &:after {
                                content: "";
                                width: 14.5px;
                                height: 17.8px;
                                background: url(../../images/atuacao/location.png);
                                position: absolute;
                                left: 0;
                                top: 2px;
                            }
                        }
                    }

                }
            }

            @media screen and (max-width: 365px) {
                h3 {
                    padding-left: 15px;

                    &:after {
                        display: none;
                    }

                    &:before {
                        display: none;
                    }
                }

                .dual-list {
                    padding-left: 15px;
                    padding-bottom: 0;
                    flex-direction: column;
                }
            }
        }



        .empresarial {
            margin-top: 60px;

            h3 {
                margin-top: 33px;
                position: relative;
                margin-bottom: 50px;

                &:after {
                    content: "";
                    height: 1px;
                    width: 45%;
                    background: #CECECE;
                    display: inline-block;
                    position: absolute;
                    right: 33px;
                    top: 16px;
                }
            }

            span {
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 20px;
                display: block;
            }

            p {
                position: relative;
                padding-left: 10px;
                margin-bottom: 8px;

                &:after {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 9px;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    background-color: $color_gold;
                }
            }

            @media screen and (max-width: 365px) {
                padding: 0px 30px;
                margin-top: 0;

                h3 {
                    &:after {
                        display: none;
                    }
                }

                .list {
                    margin-bottom: 30px;
                }
            }
        }



        .familia {
            margin-top: 60px;

            h3 {
                margin-top: 33px;
                position: relative;
                margin-bottom: 50px;

                &:after {
                    content: "";
                    height: 1px;
                    width: 80%;
                    background: #CECECE;
                    display: inline-block;
                    position: absolute;
                    right: 33px;
                    top: 16px;
                }


            }

            p {
                position: relative;
                padding-left: 10px;
                margin-bottom: 8px;

                &:after {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 9px;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    background-color: $color_gold;
                }
            }

            @media screen and (max-width: 365px) {
                padding: 0px 30px;
                margin-top: 0;

                h3 {
                    &:after {
                        display: none;
                    }
                }

                .list {
                    margin-bottom: 30px;
                }
            }
        }
    }
}