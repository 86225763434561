@import '../../Styles/Mixin.scss';

#contato {
    background: #272727;

    #form-black {
        p {
            margin-bottom: 80px;
        }

        .box-form {
            background: #3C3C3C;
            box-shadow: -3px 9px 49px 8px rgba(0, 0, 0, 0.22);
            text-align: center;
            padding: 65px 0;

            span {
                display: block;
                margin-bottom: 40px;
                color: #fff;
            }

            form {
                padding: 0 200px;

                span {
                    font-weight: bold;
                    font-size: 15px;
                    color: #C3966C;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }

                input[type=text],
                input[type=tel],
                input[type=email] {
                    width: 100%;
                    background: #525252;
                    height: 50px;
                    border: none;
                    font-size: 15px;
                    color: #fff;
                    padding-left: 20px;
                    margin-bottom: 23px;

                    &::placeholder {
                        color: #fff;
                    }
                }

                textarea {
                    width: 100%;
                    background: #525252;
                    height: 131px;
                    border: none;
                    font-size: 15px;
                    color: #fff;
                    padding-left: 20px;
                    margin-bottom: 23px;
                    padding-top: 15px;
                    max-height: 131px;
                    min-height: 131px;

                    &::placeholder {
                        color: #fff;
                    }
                }

                .lb-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 25px;
                }

                .lb-radio {
                    padding-top: 10px;
                    margin-right: 20px;

                    input[type=radio] {
                        margin-right: 5px;
                    }

                    p.desc {
                        font-size: 15px;
                    }
                }

                p.title {
                    font-weight: bold;
                    font-size: 15px;
                    color: #C3966C;
                    margin-right: 20px;
                }

            }
        }

        @media screen and (max-width: 365px) {

            margin-bottom: 0;

            .content {
                width: 100%;
            }
            p{
                margin-bottom: 35px;
            }

            .box-form {
                box-shadow: none;
                padding: 25px 3%;
                margin-bottom: 50px;

                form {
                    padding: 0 0;
                    margin-top: 40px;

                    span {
                        font-weight: bold;
                        font-size: 15px;
                        color: #C3966C;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }

                    input[type=text] {
                        width: 100%;
                        background: #E4E4E4;
                        height: 50px;
                        border: none;
                        font-size: 15px;
                        color: #2A2A2A;
                        padding-left: 20px;
                        margin-bottom: 23px;

                        &::placeholder {
                            color: #2A2A2A;
                            font-weight: 600;
                        }
                    }

                    .lb-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 25px;

                        button {
                            border: none;
                        }
                    }

                    .ajuste-mobal {
                        display: flex !important;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start !important;
                    }

                    .lb-radio {
                        padding-top: 10px;
                        margin-right: 20px;

                        input[type=radio] {
                            margin-right: 5px;
                        }

                        p.desc {
                            font-size: 15px;
                            display: block !important;
                        }
                    }

                    p.title {
                        font-weight: bold;
                        font-size: 15px;
                        color: #C3966C;
                        margin-right: 20px;
                    }

                }
            }
        }
    }
}