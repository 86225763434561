@import '../../Styles/Mixin.scss';

#noticias {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 125px;

    h2 {
        position: relative;
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 40px;
        @media screen and (max-width: 365px) {
            margin-left: 18px;
            &::after {
                display: none;
            }
        }

        &.nt1 {
            &::after {
                content: "";
                background: #CECECE;
                width: 910px;
                height: 1px;
                position: absolute;
                bottom: 15px;
                left: calc(50% - 240px);
            }
        }
        
        &.nt3 {
            &::after {
                content: "";
                background: #CECECE;
                width: 780px;
                height: 1px;
                position: absolute;
                bottom: 15px;
                left: calc(50% - 106px);
            }
        }
    }

    .notice {
        position: relative;
        width: 327px;
        background: #fff;
        min-height: 500px;
        box-shadow: 0px 7px 36px rgba(0, 0, 0, 0.07), 0px 5.09141px 24.2648px rgba(0, 0, 0, 0.0566562), 0px 3.54375px 15.6938px rgba(0, 0, 0, 0.04725), 0px 2.32422px 9.73828px rgba(0, 0, 0, 0.0404687), 0px 1.4px 5.85px rgba(0, 0, 0, 0.035), 0px 0.738281px 3.48047px rgba(0, 0, 0, 0.0295312), 0px 0.30625px 2.08125px rgba(0, 0, 0, 0.02275), 0px 0.0710938px 1.10391px rgba(0, 0, 0, 0.0133437);
        margin-bottom: 50px;
        text-align: left;

        img {
            width: 327px;
        }

        .texts {
            padding: 20px 14px 0 14px;
            height: 100%;
            display: inline-block;

            h4 {
                font-size: 18px;
                padding-bottom: 12px;

                &:after {
                    content: "";
                    width: 231px;
                    height: 1px;
                    background: linear-gradient(90deg, #C3966C 0%, #FFF3D3 100%);
                    display: block;
                    margin-top: 12px;
                }
            }

            p {
                font-size: 14px;
            }

            span {
                font-size: 11px;
                position: absolute;
                bottom: 15px;
                left: 14px;
            }
        }
    }

    ul {
        margin-top: 50px;

        li {
            margin: 0 5px;
            cursor: pointer;
            user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;

            img {
                user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }


        }
    }

    @media screen and (max-width: 365px) {
        margin-top: 0;
    }
}