@media screen and (max-width: 750px){
    #header{
        width: 100%;
        z-index: 9;
        top: 0;
        padding: 0;
        .logo{
            margin-top: 20px;
            margin-left: 15px;
            img{
                width: 200px;
            }
        }
        .menu-mobile{
            a{
                font-size: 30px;
            }
            .bt{
                padding: 45px 10px;
                text-align: center;
            }
            .icon-rede{
                margin-top: 25px;
                width: 55px;
                display: inline-block;
                float: left;
                margin-right: 25px;
            }
        }
    }
    #footer1{
        display: none;
    }
    #footer2{
        height: 60px !important;
        .alft{
            height: 30px !important;
            display: flex;
            align-items: center !important;
            justify-content: center !important;
        }
    }

    #planos {
        margin: 100px 0;
        padding: 0;
        .box{
            margin-bottom: 75px;
            .list span {
                display: flex;
                font-size: 30px;
                line-height: 33px;
                margin-bottom: 27px;
            }
        }
        .last-box{
            margin-bottom: 0;
        }
    }
    #Maisinfos{
        .buttons{
            flex-direction: column;
            a{
                display: block;
                width: 100%;
                padding: 25px 0;
                margin-bottom: 27px;
            }   
            a:last-child{
                margin-bottom: 0;
            }
        }
    }
    #footer-beneficios{
        background-size: cover;
        .buttons{
            flex-direction: column;
            a{
                display: block;
                width: 100%;
                padding: 30px 0;
                margin-bottom: 27px;
                font-size: 25px;
            }   
            a:last-child{
                margin-bottom: 0;
            }
        }
    }
}