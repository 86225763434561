@import '../../Styles/Mixin.scss';


#faq {
    padding: 65px 0 0;
    margin-top: 150px;
    h3 {
        font-weight: bold;
        font-size: 25px;
        color: #C3966C;
        margin-bottom: 45px;
    }

    .box-faq {
        cursor: pointer;

        a {
            background: #EEEEEE;
            font-size: 18px;
            line-height: 172.5%;
            color: #272727;
            width: 100%;
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 32px 0 18px;
            font-weight: 700;
            margin-bottom: 15px;
            cursor: pointer;

            img {
                width: 21px;
                height: 17px;
                transform: rotate(270deg);
                transition: 0.5s;
                cursor: pointer;
            }

            span {
                cursor: pointer;
            }

            &.active {
                background: linear-gradient(90deg, #C3966C 0%, #FFF3D3 100%);
                color: #fff;

                img {
                    transform: rotate(360deg);
                    transition: 0.5s;
                }
            }
        }

        p {
            font-size: 15px;
            line-height: 172.5%;
            color: #2B2B2B;
            margin-top: 5px;
            margin-bottom: 30px;
            display: none;
            transition: 0.5s;

            &.active {
                display: block;
            }
        }
    }

    .faqimage {
        margin-top: -130px;
    }

    .text {
        font-size: 14px;
        line-height: 172.5%;
        color: #2B2B2B;

        a {
            color: #C3966C;
            font-weight: 700;
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 365px) {
        padding: 45px 0;
        margin-top: 0px;
        margin-bottom: 0;

        h3 {
            text-align: center;
            line-height: 150%;
            margin-bottom: 25px;
        }

        .box-faq {
            a {
                height: auto;
                padding: 6px 32px 6px 18px;
            }

            p {
                padding: 0 10px
            }
        }

        .faqimage {
            display: none;
        }

        .text {
            display: none;
        }
    }

}